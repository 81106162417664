.base {
  border-bottom: var(--u-spacing-4) solid var(--u-color-white);
  border-top: var(--u-spacing-4) solid var(--u-color-white);

  &:nth-child(even) {
    background-color: var(--u-color-neutral-050);
  }
}

.row-title {
  padding: var(--u-spacing-8) 0;
  border-top-left-radius: var(--u-border-radius-12);
  border-bottom-left-radius: var(--u-border-radius-12);
}

.row-data-wrapper {
  width: 148px;
  padding: var(--u-spacing-8) var(--u-spacing-24) var(--u-spacing-8) 0;
  border-top-right-radius: var(--u-border-radius-12);
  border-bottom-right-radius: var(--u-border-radius-12);
}
