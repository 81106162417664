.base {
  background-color: var(--u-color-royal-900);
  min-height: 100vh;
  background-repeat: no-repeat;
  display: flex;
  flex-direction: column;

  &--error-404 {
    @media (--breakpoint-md-up) {
      background-position: 100% 100%;
    }

    @media (--breakpoint-md-down) {
      background-position: 70% 100%;
      background-size: 590px;
    }
  }

  &--error-403 {
    @media (--breakpoint-md-up) {
      background-position: right 152px bottom 0;
    }

    @media (--breakpoint-md-down) {
      background-position: 30% 100%;
      background-size: 240px;
    }
  }

  &--error-500 {
    @media (--breakpoint-md-up) {
      background-position: left 80% bottom -100%;
    }

    @media (--breakpoint-md-down) {
      background-position: right 25px bottom -40%;
      background-size: 420px;
    }
  }
}

.main {
  @media (--breakpoint-md-up) {
    padding-top: 200px;
  }

  flex: 1;
}

.inner {
  width: min(100%, 500px);
}

.header {
  @media (--breakpoint-md-up) {
    display: none;
  }
}

.header-container {
  display: flex;
  justify-content: right;
  padding-top: var(--u-spacing-24);
  padding-bottom: var(--u-spacing-12);
}

.logo {
  @media (--breakpoint-md-up) {
    width: 145px;
  }

  @media (--breakpoint-md-down) {
    width: 100px;
  }
}

.error-code {
  @media (--breakpoint-md-up) {
    font-size: 160px;
    line-height: 160px;
  }

  @media (--breakpoint-md-down) {
    font-size: 72px;
    line-height: 72px;
  }
}

.title {
  @media (--breakpoint-md-up) {
    font-size: var(--u-text-colossal-font-size);
    line-height: var(--u-text-colossal-font-size);
  }

  @media (--breakpoint-md-down) {
    font-size: var(--u-text-huge-font-size);
    line-height: var(--u-text-huge-font-size);
  }
}

.description {
  @media (--breakpoint-md-up) {
    font-size: var(--u-text-big-font-size);
    line-height: var(--u-text-big-line-height);
  }

  @media (--breakpoint-md-down) {
    font-size: var(--u-text-base-font-size);
    line-height: var(--u-text-base-line-height);
  }
}

.action-button {
  width: 280px;
}

.footer {
  @media (--breakpoint-md-down) {
    display: none;
  }
}
