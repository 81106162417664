.base {
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;

  &--body-centered {
    .body {
      margin-top: 24px;
      margin-bottom: 24px;
      display: flex;
      flex-direction: column;
      justify-content: center;
    }
  }
}

.body {
  flex-grow: 1;
}
