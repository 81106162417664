@import '../../../../styles/src/lib/media.css';

.base {
  background-color: var(--u-color-royal-050);
  overflow: hidden;
  display: flex;
  height: 100%;

  @media (--breakpoint-sm-up) {
    height: 730px;
    border-radius: var(--u-border-radius-24);
    box-shadow: var(--u-box-shadow-main);
    transform: translateZ(0);
  }
}

.scrollable-area {
  flex-grow: 1;
  display: flex;
  flex-direction: column;

  @media (--breakpoint-sm-up) {
    max-height: 730px;
  }

  -ms-overflow-style: none; /* for Internet Explorer, Edge */
  scrollbar-width: none; /* for Firefox */
  overflow-y: scroll;

  &::-webkit-scrollbar {
    display: none; /* for Chrome, Safari, and Opera */
  }
}

.body {
  width: 100%;
  display: flex;
  flex-direction: column;
  padding: var(--u-spacing-24);
}

.phone-mockup-wrapper {
  flex-grow: 1;
  display: flex;
}
