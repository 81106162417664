.base {
  height: 4px;
  overflow: hidden;

  background-color: var(--u-color-royal-100);

  &--color-warning {
    --u-countdownBar-inner-background-color: var(--u-color-vitaminC-500);

    background-color: var(--u-color-vitaminC-050);
  }

  &.is-running {
    --u-countdownBar-animation-play-state: running;
  }
}

.inner {
  width: 100%;
  height: 100%;

  background-color: var(
    --u-countdownBar-inner-background-color,
    var(--u-color-royal-500)
  );

  animation: slide var(--u-countdownBar-animation-duration) linear forwards;
  animation-play-state: var(--u-countdownBar-animation-play-state, paused);
}

@keyframes slide {
  to {
    transform: translateX(-100%);
  }
}
