.base {
  display: flex;
  flex-direction: column;
  color: pink;
  gap: var(--u-spacing-4);

  &--type-primary {
    color: var(--u-color-royal-500);
  }

  &--type-success {
    color: var(--u-color-alien-500);
  }
  &--type-error {
    color: var(--u-color-coral-400);
  }
  &--type-muted {
    color: var(--u-color-neutral-400);
  }
}

.info-wrapper {
  display: flex;
  gap: var(--u-spacing-4);
  align-items: center;

  &--align-left {
    justify-content: left;
  }
  &--align-right {
    justify-content: right;
  }
}
