.root {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;
  margin-bottom: var(--u-spacing-8);
  padding-top: 14px;
  padding-bottom: 14px;

  &:last-of-type {
    margin-bottom: 0;
  }

  border: 1px solid var(--u-color-royal-100);
  border-radius: var(--u-spacing-12);
}
