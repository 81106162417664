.base {
  white-space: nowrap;
  text-align: left;
  padding: var(--u-spacing-8) 0;

  &:first-child {
    padding-left: var(--u-spacing-24);
  }

  @media (--breakpoint-md-up) {
    padding: var(--u-spacing-16);

    &:first-child {
      padding-left: var(--u-spacing-48);
    }
    &:last-child {
      padding-right: var(--u-spacing-48);
    }
  }
}
