.base {
  width: 100%;
  padding: 0;

  cursor: pointer;

  text-align: left;

  &.is-disabled {
    cursor: not-allowed;

    .icon-wrapper {
      opacity: 50%;
    }
  }
}

.middle-slot {
  display: flex;
  flex-direction: column;
}

.right-slot {
  display: flex;
  flex-direction: column;
  text-align: right;
  align-items: flex-end;
}

.tooltip {
  display: flex;
}

.info-text {
  display: flex;
  align-items: center;
  gap: var(--u-spacing-4);
}
