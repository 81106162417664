@import '../../../../../styles/src/lib/media.css';

.wrapper {
  width: 172px;
  margin-top: var(--u-spacing-12);
  padding: var(--u-spacing-16) var(--u-spacing-12);

  border: 1px solid var(--u-color-royal-100);
  border-radius: var(--u-spacing-16);
  background: var(--u-color-white);
  box-shadow: var(--u-box-shadow-base);

  @media (--breakpoint-md-up) {
    padding: var(--u-spacing-16);
  }
}

.styled-popover {
  z-index: 1;

  &.is-open {
    z-index: 2;
  }
}
