.base {
  overflow: hidden;

  border: 1px solid var(--u-color-royal-100);
  border-radius: var(--u-border-radius-12);

  &:focus {
    outline: none;
  }

  &:not(:disabled) {
    &:is(:focus-visible, :hover),
    &.is-selected {
      /* stylelint-disable indentation */
      background-image: linear-gradient(
          var(--u-color-white),
          var(--u-color-white)
        ),
        linear-gradient(90deg, #6932d4 0%, #ff0bd8 100%);
      /* stylelint-enable indentation */

      border-color: transparent;
      box-shadow: var(--u-box-shadow-soft);
      background-origin: border-box;
      background-clip: content-box, border-box;
    }

    transition: border-color, box-shadow, 0.2s ease-in-out;
  }

  &--borderless {
    border-color: transparent;
  }
}

.inner {
  min-height: 56px;
  display: grid;
  align-items: center;
  grid-template-columns: auto 1fr auto;
  padding: var(--u-spacing-8) var(--u-spacing-12);
  column-gap: var(--u-spacing-4);

  background-color: var(--u-color-white);
}

.left-slot {
  grid-column: 1/2;
}

.middle-slot {
  grid-column: 2/3;
}

.right-slot {
  grid-column: 3/-1;
}
