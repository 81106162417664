.base {
  display: flex;
  align-items: center;
}

.icon-wrapper {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 28px;
  width: 28px;
  margin-left: calc(var(--u-spacing-8) * -1);

  background-color: var(--u-color-white);
  border-radius: var(--u-border-radius-full);

  &--first {
    margin-left: 0;
  }
}
