.base {
  position: relative;
  z-index: 1;
  display: inline-flex;
}

.tooltip {
  --u-tooltip-arrow-corner-distance: var(--u-spacing-16);
  z-index: 1;
  display: flex;
  width: max-content;
  max-width: 192px;

  &--placement-bottom-start {
    > .arrow {
      top: 0;
      left: var(--u-tooltip-arrow-corner-distance);
      transform: translate(0, -100%);
    }
  }

  &--placement-bottom {
    > .arrow {
      left: 50%;
      top: 0;
      z-index: 1000;
      transform: translate(-50%, -100%);
    }
  }

  &--placement-bottom-end {
    > .arrow {
      top: 0;
      transform: translate(0, -100%);
      right: var(--u-tooltip-arrow-corner-distance);
    }
  }

  &--placement-top-start {
    > .arrow {
      bottom: 0;
      transform: translate(0, 100%) rotate(180deg);
      left: var(--u-tooltip-arrow-corner-distance);
    }
  }

  &--placement-top {
    > .arrow {
      bottom: 0;
      left: 50%;
      transform: translate(-50%, 100%) rotate(180deg);
    }
  }

  &--placement-top-end {
    > .arrow {
      right: var(--u-tooltip-arrow-corner-distance);
      bottom: 0;
      transform: translate(0, 100%) rotate(180deg);
    }
  }

  &--placement-left-start {
    > .arrow {
      top: var(--u-tooltip-arrow-corner-distance);
      right: 0;
      transform: translateX(100%) rotate(90deg);
    }
  }

  &--placement-left {
    > .arrow {
      right: 0;
      top: 50%;
      transform: translate(100%, -50%) rotate(90deg);
    }
  }

  &--placement-left-end {
    > .arrow {
      bottom: var(--u-tooltip-arrow-corner-distance);
      right: 0;
      transform: translateX(100%) rotate(90deg);
    }
  }

  &--placement-right-start {
    > .arrow {
      left: 0;
      top: var(--u-tooltip-arrow-corner-distance);
      transform: translateX(-100%) rotate(-90deg);
    }
  }

  &--placement-right {
    > .arrow {
      left: 0;
      top: 50%;
      transform: translate(-100%, -50%) rotate(-90deg);
    }
  }

  &--placement-right-end {
    > .arrow {
      bottom: var(--u-tooltip-arrow-corner-distance);
      left: 0;
      transform: translateX(-100%) rotate(-90deg);
    }
  }
}

.content {
  padding: var(--u-spacing-8) var(--u-spacing-12);

  background-color: var(--u-color-neutral-900);
  border-radius: var(--u-border-radius-8);
  color: var(--u-color-neutral-100);

  font-size: var(--u-text-tiny-font-size);
  line-height: var(--u-text-tiny-line-height);
}

.arrow {
  position: absolute;
  width: 0;
  height: 0;

  border-color: transparent transparent var(--u-color-neutral-900);
  border-style: solid;
  border-width: 6px;
}
