.base {
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: var(--u-spacing-8);
  text-align: center;
}

.emoji {
  font-size: 64px;
}
