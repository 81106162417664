@import '../../../../../styles/src/lib/media.css';

.root {
  position: relative;
  right: var(--u-spacing-16);
  padding: var(--u-spacing-8);

  border-radius: var(--u-spacing-8);
  color: var(--u-color-royal-500);

  svg {
    pointer-events: none;
  }

  &:hover {
    background: var(--u-color-royal-500);
    color: var(--u-color-white);
  }

  @media (--breakpoint-lg-up) {
    right: 0;
  }
}
