.wrapper {
  width: 344px;
  margin-top: var(--u-spacing-12);
  padding: var(--u-spacing-12);

  border-radius: var(--u-border-radius-12);
  background: var(--u-color-white);
  border: 1px solid var(--u-color-royal-100);
  box-shadow: var(--u-box-shadow-soft);
}
