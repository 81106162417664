.root {
  display: flex;
  gap: var(--u-spacing-24);

  width: 100%;
  padding: var(--u-spacing-32);

  border-radius: var(--u-spacing-16);
  background: var(--u-color-white);

  @media (--breakpoint-md-down) {
    padding: var(--u-spacing-16);
  }
}

.logo-wrapper {
  display: flex;
  justify-content: center;
  align-items: center;
  flex-shrink: 0;
  width: 48px;
  height: 48px;
  border-radius: var(--u-spacing-16);
  background-color: var(--u-color-royal-100);

  @media (--breakpoint-md-down) {
    width: 32px;
    height: 32px;
    border-radius: var(--u-spacing-8);
  }
}

.stats-wrapper {
  display: flex;
  flex-direction: column;
  gap: var(--u-spacing-4);
}

.main-wrapper {
  display: flex;
  flex-direction: column;
  gap: var(--u-spacing-8);
}

.value {
  @media (--breakpoint-md-down) {
    font-size: var(--u-text-large-font-size);
    line-height: var(--u-text-large-line-height);
  }
}

.link {
  font-size: var(--u-text-tiny-font-size);
  letter-spacing: var(--u-text-tiny-callout-letter-spacing);
  line-height: var(--u-text-tiny-line-height);
  text-transform: uppercase;
  font-style: italic;
  font-weight: 400;
  color: var(--u-color-royal-500);
}
