.base {
  white-space: nowrap;
  padding: var(--u-spacing-16) 0;
  text-align: left;

  &--last-data-cell {
    text-align: right;
    padding-right: var(--u-spacing-8);
  }

  &.is-collapsible {
    display: none;
  }

  @media (--breakpoint-md-up) {
    padding: var(--u-spacing-24) var(--u-spacing-12);

    &--first-data-cell {
      padding-left: var(--u-spacing-48);
    }

    &--last-data-cell {
      padding-right: var(--u-spacing-48);
      text-align: left;
    }

    &.is-collapsible {
      display: table-cell;
    }
  }
}
