.base {
  padding: var(--u-spacing-24);

  text-align: center;

  &.is-touched {
    .option {
      &:not(.is-selected) {
        opacity: 0.3;
      }
    }
  }
}

.title {
  color: var(--u-color-black);

  font-size: var(--u-text-small-font-size);
  line-height: var(--u-text-small-line-height);
}

.options {
  display: flex;
  justify-content: center;
  margin-top: var(--u-spacing-8);
  gap: var(--u-spacing-8);
}
