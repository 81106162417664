.base {
  position: relative;
  z-index: 0;

  display: flex;
  align-items: stretch;
  width: 100%;
  height: 56px;
  padding: 0;
  overflow: hidden;

  background-color: var(--u-color-white);
  border: 0;
  border-radius: var(--u-border-radius-12);
  box-shadow: 0 0 0 1px var(--u-color-royal-100);
  cursor: pointer;

  &.has-value .label {
    transform: translate(0, -10px) scale(0.85);
  }
}

.main-wrapper {
  flex-grow: 1;

  padding-top: var(--u-spacing-24);
  padding-right: var(--u-spacing-16);
  padding-bottom: var(--u-spacing-8);
  padding-left: var(--u-spacing-16);

  overflow: hidden;

  text-align: left;

  &--no-label {
    display: flex;
    flex-direction: column;
    justify-content: center;
    padding-top: var(--u-spacing-8);
  }
}

.label {
  position: absolute;
  top: 18px;

  color: var(--u-color-neutral-400);

  font-size: var(--u-text-small-font-size);
  line-height: var(--u-text-small-line-height);

  transform-origin: left top;

  transition: transform 200ms cubic-bezier(0, 0, 0.2, 1);
}

.selected-option {
  padding-right: var(--u-spacing-16);
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}

.indicator {
  display: inline-flex;
  align-items: center;
  justify-content: center;
  padding: var(--u-spacing-16);

  background-color: var(--u-color-royal-100);
  color: var(--u-color-royal-500);

  font-size: 16px;
}
