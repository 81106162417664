.base {
  position: relative;
  background-color: transparent;
  border: 0;
  border-radius: 8px;
  display: flex;
  align-items: center;
  justify-content: center;
  text-align: center;
  cursor: pointer;
  transition: background-color 0.2s;
  flex-direction: column;
  border: 1px solid transparent;

  &:hover {
    border: 1px solid var(--u-color-neutral-100);
  }
}

.selected {
  background-color: var(--u-color-primary-100);
  pointer-events: none;
}

.emoji {
  display: flex;
  align-items: center;
  justify-content: center;
  line-height: 1;
}

.emoji {
  position: absolute;
}

.label-text {
  color: var(--u-color-neutral-500);
  white-space: nowrap;
  font-size: var(--u-text-tiny-font-size);
  line-height: var(--u-text-tiny-line-height);
  position: absolute;
}
