.base {
  display: grid;
  grid-template-columns: var(--u-spacing-20) auto auto;
  gap: var(--u-spacing-4);
  align-items: center;
}

.ticker-image-wrapper {
  height: 20px;
  width: 20px;
  display: flex;
}

.tooltip-icon {
  display: flex;
}
