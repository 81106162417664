.trigger {
  &[data-state='open'] {
    .button-arrow {
      transform: rotate(-180deg);
    }
  }

  &[data-state='close'] {
    .button-arrow {
      transform: rotate(-180deg);
    }
  }
}

.button-arrow {
  transition-property: transform;
  transition-duration: 300ms;
  margin-right: var(--u-spacing-4);
  color: inherit;
}

.item {
  padding: var(--u-spacing-8) var(--u-spacing-16);
  background-color: var(--u-color-white);
  border-radius: var(--u-border-radius-12);
  border: 1px solid var(--u-color-royal-100);

  font-size: var(--u-text-small-font-size);
  line-height: var(--u-text-small-line-height);

  color: var(--u-color-neutral-900);

  &:hover,
  &:focus {
    background-color: var(--u-color-royal-050);
    outline: 0;
  }
}

.content {
  animation-duration: 500ms;
  animation-timing-function: cubic-bezier(0.16, 1, 0.3, 1);
  will-change: transform, opacity;

  &[data-side='bottom'] {
    animation-name: slideDownAndFade;
  }
}

@keyframes slideDownAndFade {
  from {
    opacity: 0;
    transform: translateY(-10px);
  }
  to {
    opacity: 1;
    transform: translateY(0);
  }
}
