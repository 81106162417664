.base {
  position: relative;
  z-index: 0;

  display: flex;
  padding: var(--u-spacing-8) var(--u-spacing-12);

  background-color: var(--u-color-royal-050);
  border-radius: var(--u-border-radius-12);

  &:focus-within {
    box-shadow: 0 0 0 2px var(--u-color-royal-200);
  }
}

.left-icon {
  color: var(--u-color-black);

  font-size: 24px;
}

.input {
  width: 100%;
  padding: 0;
  margin-left: 10px;

  background-color: transparent;
  border: 0;
  color: var(--u-color-neutral-900);

  font-size: var(--u-text-base-font-size);
  line-height: var(--u-text-base-line-height);

  &:not(:focus) {
    font-weight: 600;

    &:placeholder-shown {
      color: var(--u-color-neutral-500);

      font-weight: 400;
    }
  }

  &:focus {
    outline: none;
  }
}
