.base {
  padding: var(--u-spacing-24);
  display: grid;
  grid-template-columns: 24px 1fr 24px;
  align-items: center;
}

.content {
  display: flex;
  align-items: center;
  justify-content: center;
  grid-column: 2 / 3;
}

.close-button {
  background-color: var(--u-color-neutral-400);
  border-radius: var(--u-border-radius-full);
  color: var(--u-color-white);
  align-items: center;
  justify-content: center;
  width: 24px;
  height: 24px;
}
