@import '../../../../styles/src/lib/media.css';

.root {
  position: relative;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  gap: var(--u-spacing-16);

  width: 100%;
  padding: var(--u-spacing-24) var(--u-spacing-16);

  border: 1px solid var(--u-color-royal-100);
  border-radius: var(--u-spacing-16);
  background: var(--u-color-white);

  @media (--breakpoint-lg-up) {
    flex-direction: row;
    gap: var(--u-spacing-24);
    padding: var(--u-spacing-24);
  }
}

.logo-wrapper {
  padding: var(--u-spacing-8);
  line-height: 0;
  border-radius: var(--u-spacing-16);
  background: var(--u-color-royal-050);

  @media (--breakpoint-lg-up) {
    padding: var(--u-spacing-12);
  }
}

.asset-name {
  text-transform: uppercase;
}

.dropdown-wrapper {
  position: absolute;
  top: var(--u-spacing-24);
  right: 0;

  @media (--breakpoint-lg-up) {
    right: var(--u-spacing-24);
  }
}
