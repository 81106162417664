.title {
  color: var(--u-color-black);

  font-size: var(--u-text-big-font-size);
  font-weight: 600;
  line-height: var(--u-text-big-line-height);
}

.description {
  margin-top: var(--u-spacing-8);

  color: var(--u-color-neutral-500);

  font-size: var(--u-text-small-font-size);
  line-height: var(--u-text-small-line-height);
}
