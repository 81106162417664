.base {
  color: var(--u-color-cerulean-500);
  text-decoration: none;

  &:hover {
    color: var(--u-color-cerulean-600);
    text-decoration: underline;
  }

  &.is-disabled {
    color: var(--u-color-neutral-300);
  }
}
