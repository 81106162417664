.base {
  display: flex;
  align-items: center;
  justify-content: center;

  &--inline {
    display: inline-flex;
  }

  &--direction-column {
    flex-direction: column;
  }
}
