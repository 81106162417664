.root {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;

  padding: var(--u-spacing-12) var(--u-spacing-16);

  color: var(--u-color-neutral-400);
  border-radius: var(--u-spacing-16);

  &.is-active {
    color: var(--u-color-royal-500);
    background: var(--u-color-royal-050);
  }

  &:hover:not(&.is-active) {
    color: var(--u-color-royal-500);
  }

  &:not(:first-of-type) {
    margin-left: calc(var(--u-spacing-4) * -1);
  }

  @media (--breakpoint-md-up) {
    padding-right: var(--u-spacing-24);
    padding-left: var(--u-spacing-24);
  }
}

.right-slot {
  margin-left: var(--u-spacing-4);
  line-height: 0;
}
