.row-body {
  border-bottom: 1px solid var(--u-color-royal-050);

  &:last-child {
    border-bottom: none;
  }
}

.collapse-button-wrapper {
  padding: 0 var(--u-spacing-12) 0 var(--u-spacing-8);
  width: 2px;

  @media (--breakpoint-md-up) {
    display: none;
  }
}
