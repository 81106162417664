.base {
  position: fixed;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  z-index: 9999;

  background-color: var(--u-color-royal-500);
}

.container {
  display: flex;
  flex-direction: column;
  align-items: center;
  height: 100%;
  padding: var(--u-spacing-24) var(--u-spacing-24);
}

.header {
  width: 100%;

  text-align: right;
}

.close-button {
  background-color: transparent;
  border: 0;
  color: var(--u-color-white);
  cursor: pointer;
  transform: translateY(-12px);

  font-size: 32px;
}

.content {
  margin-bottom: var(--u-spacing-24);
}

.nav {
  display: flex;
  align-items: center;
  flex-grow: 1;
}

.nav-list {
  list-style: none;
  padding-left: 0;
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: var(--u-spacing-48);
}

.link {
  display: flex;
  align-items: center;
  gap: var(--u-spacing-8);
}
