@import '../../../../../styles/src/lib/media.css';

.base {
  display: flex;
  margin-top: var(--u-spacing-24);
  gap: var(--u-spacing-16);

  > * {
    flex-grow: 1;
    flex-basis: 0;
  }

  @media (--breakpoint-md-down) {
    flex-direction: column;
    gap: var(--u-spacing-8);
  }
}
