.base {
  white-space: nowrap;
  padding: var(--u-spacing-24) var(--u-spacing-24);
  border-bottom: 1px solid var(--u-color-royal-050);
  font-size: var(--u-text-tiny-font-size);
  letter-spacing: var(--u-text-tiny-callout-letter-spacing);
  line-height: var(--u-text-tiny-line-height);

  @media (--breakpoint-md-up) {
    padding: var(--u-spacing-32) var(--u-spacing-32) var(--u-spacing-24);
    font-size: var(--u-text-base-font-size);
    letter-spacing: var(--u-text-base-callout-letter-spacing);
    line-height: var(--u-text-base-line-height);
  }
}
