.base {
  max-width: 1248px;
  padding-right: var(--u-spacing-24);
  padding-left: var(--u-spacing-24);
  margin-right: auto;
  margin-left: auto;

  &--fluid {
    max-width: 100%;
  }
}
