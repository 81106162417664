.base {
  --u-input-border-radius: var(--u-border-radius-12);

  position: relative;
  z-index: 0;

  &--with-label {
    --u-input-font-size: var(--u-text-base-font-size);
    --u-input-line-height: var(--u-text-base-line-height);
    --u-input-padding-top: var(--u-spacing-24);
    --u-input-padding-bottom: var(--u-spacing-8);
    --u-input-padding-left: var(--u-spacing-24);
  }

  &--with-left-icon {
    /* Icon left value (12) + Icon width (32) + Space from icon to input text (8) = 52 */
    --u-input-padding-left: 52px;
  }

  &.has-error {
    --u-input-color: var(--u-color-coral-500);
    --u-input-right-button-color: var(--u-color-coral-500);
    --u-input-right-button-background-color: var(--u-color-coral-100);
    --u-input-border-color: var(--u-color-coral-500);
  }

  &.is-focused:not(.has-error)::before {
    width: calc(100% + 4px);
    height: calc(100% + 4px);

    background-image: linear-gradient(90deg, #6932d4 0%, #ff0bd8 100%);
    border-radius: calc(var(--u-input-border-radius) + 2px);

    transform: translate(-2px, -2px);
  }

  &::before {
    content: '';

    position: absolute;

    top: 0;
    left: 0;
    z-index: -1;

    width: calc(100% + 2px);
    height: calc(100% + 2px);

    background-color: var(--u-input-border-color, var(--u-color-royal-100));
    border-radius: calc(var(--u-input-border-radius) + 1px);

    transform: translate(-1px, -1px);
  }
}

.main {
  display: flex;
  align-items: center;
  min-height: 54px;
  overflow: hidden;

  background-color: var(--u-color-white);
  border-radius: var(--u-input-border-radius);
}

.input-wrapper {
  position: relative;
  z-index: 0;

  align-self: stretch;
  flex-grow: 1;
  display: flex;
}

.label {
  position: absolute;
  top: 18px;
  left: var(--u-input-padding-left, var(--u-spacing-16));

  color: var(--u-color-neutral-400);
  pointer-events: none;

  font-size: var(--u-text-small-font-size);
  line-height: var(--u-text-small-line-height);

  transform-origin: left top;

  transition: transform 200ms cubic-bezier(0, 0, 0.2, 1);
}

.input {
  width: 100%;
  padding-top: var(--u-input-padding-top, 0);
  padding-right: var(--u-spacing-12);
  padding-bottom: var(--u-input-padding-bottom, 0);
  padding-left: var(--u-input-padding-left, var(--u-spacing-24));

  border: 0;
  border-radius: var(--u-input-border-radius);
  color: var(--u-input-color, var(--u-color-neutral-900));

  font-size: var(--u-input-font-size, var(--u-text-small-font-size));
  line-height: var(--u-input-line-height, var(--u-text-small-line-height));

  &:focus {
    outline: none;
  }

  &:focus + .label,
  &:not(:placeholder-shown) + .label {
    transform: translate(0, -10px) scale(0.85);
  }

  &::placeholder {
    color: var(--u-color-neutral-400);
  }

  &[type='number']::-webkit-inner-spin-button,
  &[type='number']::-webkit-outer-spin-button {
    opacity: 0;
  }
}

.left-icon-wrapper {
  position: absolute;
  left: var(--u-spacing-12);

  width: 32px;
  height: 32px;
  overflow: hidden;

  z-index: 1;
}
