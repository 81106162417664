.base {
  display: flex;
  align-items: center;
}

.title-wrapper {
  margin-right: auto;

  &:only-child {
    margin-left: auto;
  }
}
