.root {
  display: flex;
  min-height: var(--u-spacing-56);
  padding: var(--u-spacing-4);
  border: 1px solid var(--u-color-royal-100);
  border-radius: var(--u-spacing-16);
  background: var(--u-color-white);

  @media (--breakpoint-md-up) {
    width: fit-content;
  }
}
