.base {
  position: relative;
  z-index: 1;

  width: var(--radix-select-trigger-width);
  overflow: hidden;
  max-height: min(var(--radix-select-content-available-height), 198px);

  /* +1px shadow width */
  margin-top: calc(var(--u-spacing-4) + 1px);
  margin-bottom: 0;
  overflow: auto;

  background-color: var(--u-color-white);
  border: 1px solid var(--u-color-royal-100);
  border-radius: var(--u-border-radius-12);
}
