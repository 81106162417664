.base {
  animation: spin 1.3s cubic-bezier(0.445, 0.05, 0.55, 0.95) infinite;
}

@keyframes spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}
