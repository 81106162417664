.base {
  position: sticky;
  top: 0;
  z-index: 0;

  padding: 22px var(--u-spacing-24);

  &--color-default {
    background-color: var(--u-color-white);
    color: var(--u-color-royal-500);
  }

  &--color-warning {
    background-color: var(--u-color-vitaminC-100);
    color: var(--u-color-vitaminC-500);
  }

  &--color-success {
    background-color: var(--u-color-alien-100);
    color: var(--u-color-alien-500);
  }

  &--color-error {
    background-color: var(--u-color-coral-100);
    color: var(--u-color-coral-500);
  }

  &--color-neutral {
    background-color: var(--u-color-neutral-100);
    color: var(--u-color-neutral-500);
  }
}

.countdown-bar {
  position: absolute;
  right: 0;
  bottom: 0;
  left: 0;
}
