.base {
  position: relative;
  z-index: 0;

  display: inline-flex;
  width: 16px;
  height: 16px;

  cursor: pointer;
}

.input {
  position: absolute;
  top: 0;
  left: 0;
  z-index: 1;

  width: 100%;
  height: 100%;
  appearance: none;

  cursor: inherit;
  opacity: 0;

  &:focus-visible + .box {
    --u-radio-background-color: var(--u-color-royal-100);

    outline: 1px solid var(--u-color-royal-500);
  }

  &:checked + .box {
    --u-radio-border-color: var(--u-color-royal-500);
    --u-radio-box-background-shape-opacity: 1;
  }
}

.box {
  position: absolute;
  top: 0;
  left: 0;

  display: flex;
  align-items: center;
  justify-content: center;

  width: 100%;
  height: 100%;

  background-color: var(--u-radio-background-color);
  border: 1px solid var(--u-radio-border-color, var(--u-color-neutral-600));
  border-radius: var(--u-border-radius-full);

  &::before {
    content: "";

    width: 10px;
    height: 10px;

    background-color: var(--u-color-royal-500);
    border-radius: var(--u-border-radius-full);
    opacity: var(--u-radio-box-background-shape-opacity, 0);
  }
}
