.root {
  display: flex;
  align-items: center;
  justify-content: center;
  gap: var(--u-spacing-8);

  @media (--breakpoint-md-up) {
    justify-content: flex-start;
  }
}
