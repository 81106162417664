.base {
  background-color: var(--u-color-royal-500);

  @media (--breakpoint-md-down) {
    position: sticky;
    top: 0;
    z-index: 2;
  }
}

.main-bar {
  display: grid;
  align-items: center;

  @media (--breakpoint-md-up) {
    grid-template-columns: 1fr minmax(380px, auto) 1fr;
    min-height: 100px;
    padding-top: var(--u-spacing-24);
    padding-bottom: var(--u-spacing-24);
  }

  @media (--breakpoint-md-down) {
    grid-template-columns: 1fr 1fr 1fr;
    position: sticky;
    top: 0;
    z-index: 2;
    padding-top: var(--u-spacing-12);
    padding-bottom: var(--u-spacing-12);
  }
}

.left-column {
  display: flex;
}

.middle-column {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  gap: var(--u-spacing-4);
}

.right-column {
  display: flex;
  justify-content: right;
  text-align: right;
  grid-column: 3 / -1;
}

.refund-id {
  @media (--breakpoint-md-down) {
    display: none;
  }
}

.title {
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  color: var(--u-color-white);
  font-weight: 700;
  text-transform: uppercase;
  margin: 0;

  @media (--breakpoint-lg-up) {
    max-width: 250px;
  }

  @media (--breakpoint-lg-down) {
    max-width: 200px;
  }

  @media (--breakpoint-md-up) {
    font-size: var(--u-text-base-font-size);
    line-height: var(--u-text-base-line-height);
    letter-spacing: var(--u-text-base-callout-letter-spacing);
  }

  @media (--breakpoint-md-down) {
    max-width: 250px;

    font-size: var(--u-text-tiny-font-size);
    line-height: var(--u-text-tiny-line-height);
    letter-spacing: var(--u-text-tiny-callout-letter-spacing);
  }

  @media (--breakpoint-sm-down) {
    max-width: 150px;
  }
}

.hamburger {
  @media (--breakpoint-md-up) {
    display: none;
  }
}
