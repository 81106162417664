.base {
  background-color: var(--u-color-white);
  border: 1px solid var(--u-color-royal-100);
  border-radius: var(--u-border-radius-16);
}

.table {
  width: 100%;
  border-collapse: collapse;

  @media (--breakpoint-md-down) {
    width: calc(100% - var(--u-spacing-16));
    margin: 0 auto;
  }
}
