.base {
  background-color: var(--u-color-white);
  border: 1px solid transparent;
  background-image: linear-gradient(var(--u-color-white), var(--u-color-white)),
    var(--u-gradient-royal-pink);
  background-origin: border-box;
  background-clip: content-box, border-box;
  padding: 0;
  border-radius: var(--u-border-radius-full);
  color: var(--u-color-royal-500);
  cursor: pointer;
}

.inner {
  display: flex;
  align-items: center;
  gap: var(--u-spacing-4);
  padding: var(--u-spacing-4) 6px var(--u-spacing-4) 10px;
}
