.base {
  padding-top: var(--u-spacing-24);
  padding-bottom: var(--u-spacing-24);
  min-height: 80px;
}

.inner {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.navigation {
  display: flex;
  gap: var(--u-spacing-32);
}

.link {
  display: flex;
  align-self: center;
  gap: var(--u-spacing-8);
}
