.base {
  display: flex;
  gap: var(--u-spacing-12);
  text-align: left;
}

.checkbox {
  flex-shrink: 0;
  position: relative;
  z-index: 0;

  display: inline-flex;
  width: 16px;
  height: 16px;
  margin-top: 2px;

  color: var(--u-color-royal-050);

  cursor: pointer;
}

.input {
  position: absolute;
  top: 0;
  left: 0;
  z-index: 1;

  width: 100%;
  height: 100%;
  appearance: none;

  cursor: inherit;
  opacity: 0;

  &:focus-visible + .box {
    --u-checkbox-background-color: var(--u-color-royal-100);

    outline: 1px solid var(--u-color-royal-500);
  }

  &:checked + .box {
    --u-checkbox-background-color: var(--u-color-royal-500);
    --u-checkbox-border-color: var(--u-color-royal-500);
    --u-checkbox-icon-opacity: 1;
    --u-checkbox-border-width: 0;
  }
}

.box {
  position: absolute;
  top: 0;
  left: 0;

  width: 100%;
  height: 100%;

  background-color: var(--u-checkbox-background-color);
  border: var(--u-checkbox-border-width, 1px) solid
    var(--u-checkbox-border-color, var(--u-color-neutral-600));
  border-radius: var(--u-border-radius-2);

  font-size: 16px;
}

.icon {
  opacity: var(--u-checkbox-icon-opacity, 0);
}
