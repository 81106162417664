.base {
  overflow: hidden;
  display: grid;
  grid-template-rows: auto 1fr auto;
  min-height: 100dvh;
  background-color: var(--u-color-royal-500);
}

.main {
  min-width: 0;
  position: relative;
  z-index: 0;
  grid-row: 2/3;

  @media (--breakpoint-sm-up) {
    width: 432px;
    margin: auto;
    padding-top: var(--u-spacing-48);
    padding-bottom: var(--u-spacing-56);
  }
}

/* This is needed for widget enter exit animations to work. It basically overlaps two divs.  */
.widget-wrapper {
  display: grid;
  /* to fix inner element's truncate issue */
  grid-template-columns: minmax(0, 1fr);
  grid-template-rows: 1fr;

  @media (--breakpoint-md-up) {
    height: 800px;
  }

  @media (--breakpoint-md-down) {
    height: 100%;
  }

  > div {
    grid-column: 1/-1;
    grid-row: 1/-1;
    display: flex;
    flex-direction: column;
  }
}

.footer {
  grid-row: 3/-1;

  @media (--breakpoint-md-down) {
    display: none;
  }
}

.loader-wrapper {
  display: grid;
  place-items: center;
}
