.base {
  position: fixed;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  z-index: 9999;
}

.content {
  position: relative;
  z-index: 1;

  outline: 0;
}

.overlay {
  position: fixed;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  z-index: 0;

  background-color: var(--u-color-royal-800);
  opacity: 0.7;
}
