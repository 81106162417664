.base {
  @media (--breakpoint-md-up) {
    display: none;
  }
}

.collapsible-rows {
  width: 100%;
  border-collapse: collapse;
}
