.root {
  display: flex;
  align-items: center;
  justify-content: center;
  color: var(--u-color-neutral-900);
  gap: var(--u-spacing-8);

  .icon {
    display: none;
  }

  &:not(:first-of-type) {
    display: none;
  }

  @media (--breakpoint-md-up) {
    color: var(--u-color-neutral-400);

    .icon {
      display: block;
    }

    &:not(:first-of-type) {
      display: flex;
    }

    &:last-of-type {
      color: var(--u-color-neutral-900);

      .icon {
        display: none;
      }
    }
  }
}
