.base {
  position: relative;
  z-index: 0;

  /* height of the error text + 8px top gap  */
  padding-bottom: var(--u-spacing-24);
}

.error {
  position: absolute;
  bottom: 0;
  left: var(--u-spacing-24);
}
