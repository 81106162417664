.base {
  &--truncated {
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
  }

  &--transform-capitalize {
    text-transform: capitalize;
  }

  &--transform-uppercase {
    text-transform: uppercase;
  }

  &--decoration-none {
    text-decoration: none;
  }

  &--decoration-underline {
    text-decoration: underline;
  }

  &--align-left {
    text-align: left;
  }

  &--align-right {
    text-align: right;
  }

  &--align-center {
    text-align: center;
  }

  /* Sizes */

  &--size-inherit {
    font-size: inherit;
    line-height: inherit;
  }

  &--size-gargantuan {
    font-size: var(--u-text-gargantuan-font-size);
    line-height: var(--u-text-gargantuan-line-height);
  }

  &--size-colossal {
    font-size: var(--u-text-colossal-font-size);
    line-height: var(--u-text-colossal-line-height);
  }

  &--size-massive {
    font-size: var(--u-text-massive-font-size);
    line-height: var(--u-text-massive-line-height);
  }

  &--size-huge {
    font-size: var(--u-text-huge-font-size);
    line-height: var(--u-text-huge-line-height);
  }

  &--size-big {
    font-size: var(--u-text-big-font-size);
    line-height: var(--u-text-big-line-height);
  }

  &--size-large {
    font-size: var(--u-text-large-font-size);
    line-height: var(--u-text-large-line-height);
  }

  &--size-medium {
    font-size: var(--u-text-medium-font-size);
    line-height: var(--u-text-medium-line-height);
  }

  &--size-medium-callout {
    font-size: var(--u-text-medium-font-size);
    letter-spacing: var(--u-text-medium-callout-letter-spacing);
    line-height: var(--u-text-medium-line-height);
    text-transform: uppercase;
  }

  &--size-base {
    font-size: var(--u-text-base-font-size);
    line-height: var(--u-text-base-line-height);
  }

  &--size-base-callout {
    font-size: var(--u-text-base-font-size);
    letter-spacing: var(--u-text-base-callout-letter-spacing);
    line-height: var(--u-text-base-line-height);
    text-transform: uppercase;
  }

  &--size-small {
    font-size: var(--u-text-small-font-size);
    line-height: var(--u-text-small-line-height);
  }

  &--size-small-callout {
    font-size: var(--u-text-small-font-size);
    letter-spacing: var(--u-text-small-callout-letter-spacing);
    line-height: var(--u-text-small-line-height);
    text-transform: uppercase;
  }

  &--size-tiny {
    font-size: var(--u-text-tiny-font-size);
    line-height: var(--u-text-tiny-line-height);
  }

  &--size-tiny-callout {
    font-size: var(--u-text-tiny-font-size);
    letter-spacing: var(--u-text-tiny-callout-letter-spacing);
    line-height: var(--u-text-tiny-line-height);
    text-transform: uppercase;
  }

  &--size-miniscule {
    font-size: var(--u-text-miniscule-font-size);
    line-height: var(--u-text-miniscule-line-height);
  }

  &--size-miniscule-callout {
    font-size: var(--u-text-miniscule-font-size);
    letter-spacing: var(--u-text-miniscule-callout-letter-spacing);
    line-height: var(--u-text-miniscule-line-height);
    text-transform: uppercase;
  }

  &--size-superMiniscule {
    font-size: var(--u-text-superMiniscule-font-size);
    line-height: var(--u-text-superMiniscule-line-height);
  }

  &--size-superMiniscule-callout {
    font-size: var(--u-text-superMiniscule-font-size);
    letter-spacing: var(--u-text-superMiniscule-callout-letter-spacing);
    line-height: var(--u-text-superMiniscule-line-height);
    text-transform: uppercase;
  }

  /* Weights */

  &--weight-400 {
    font-weight: 400;
  }

  &--weight-600 {
    font-weight: 600;
  }

  &--weight-700 {
    font-weight: 700;
  }
}
