.icon-wrapper {
  width: 32px;
  height: 32px;
  padding: var(--u-spacing-4);
}

.supported-networks {
  display: flex;
  align-items: center;

  .icon-wrapper {
    width: 20px;
    height: 20px;
    display: flex;
    align-items: center;
    justify-content: center;
    background-color: var(--u-color-white);
    border-radius: 50%;

    &:not(:first-child) {
      margin-left: -6px;
    }
  }
}
