.base {
  overflow: hidden;
  margin-bottom: var(--u-spacing-8);
  background-color: var(--u-color-white);
  border: 1px solid var(--u-color-royal-100);
  border-radius: var(--u-border-radius-8);
  text-decoration: none;
  display: block;

  &:last-of-type {
    margin-bottom: 0;
  }

  &:hover {
    background-image: linear-gradient(
        var(--u-color-white),
        var(--u-color-white)
      ),
      linear-gradient(90deg, #6932d4 0%, #ff0bd8 100%);

    border-color: transparent;
    background-origin: border-box;
    background-clip: content-box, border-box;
    box-shadow: var(--u-box-shadow-soft);
  }
}

.inner {
  padding: var(--u-spacing-12);
}

.time-info {
  display: flex;
  align-items: center;
  gap: var(--u-spacing-4);
}

.group {
  display: flex;
  flex-direction: column;
  min-width: 0;
  flex-shrink: 0;
}

.header {
  display: flex;
  justify-content: space-between;
  gap: var(--u-spacing-16);
  text-align: right;
}

.confirm-status {
  display: flex;
  align-items: center;
  gap: var(--u-spacing-4);
}

.tx-info-row {
  margin-top: var(--u-spacing-12);
  display: flex;
  gap: var(--u-spacing-16);
}
