.base {
  position: relative;
  z-index: 0;

  display: flex;
  min-height: 48px;

  padding: var(--u-spacing-8) var(--u-spacing-12) var(--u-spacing-8)
    var(--u-spacing-8);
  overflow: hidden;

  box-shadow: var(--u-box-shadow-main);

  &--centered {
    align-items: center;
  }

  border-radius: var(--u-border-radius-12);

  &.wrapped {
    flex-direction: column;
    align-items: start;

    .main {
      margin-left: var(--u-spacing-8);
    }
  }

  &--success {
    --u-toast-status-icon-color: var(--u-color-alien-500);
    --u-toast-background-color: var(--u-color-alien-100);

    background-color: var(--u-color-alien-050);
  }

  &--error {
    --u-toast-status-icon-color: var(--u-color-coral-500);
    --u-toast-background-color: var(--u-color-coral-100);

    background-color: var(--u-color-coral-050);
  }

  &--warning {
    --u-toast-status-icon-color: var(--u-color-vitaminC-500);
    --u-toast-background-color: var(--u-color-vitaminC-100);

    background-color: var(--u-color-vitaminC-050);
  }
}

.statusIcon {
  color: var(--u-toast-status-icon-color);
  font-size: 32px;
}

.icon-wrapper {
  flex-shrink: 0;
  display: flex;
  align-items: center;
  justify-content: center;
  width: 32px;
  height: 32px;
  border-radius: var(--u-border-radius-8);
  background-color: var(--u-toast-background-color);
}

.main {
  display: flex;
  width: 100%;
  flex-direction: column;
  margin-left: var(--u-spacing-12);
}

.message {
  color: var(--u-color-black);

  font-size: var(--u-text-base-font-size);
  line-height: var(--u-text-base-line-height);
}
