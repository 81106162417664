.base {
  width: 16px;
  height: 16px;
  margin: var(--u-spacing-4);
  display: flex;
  justify-content: center;
  align-items: center;
  border: none;
  background-color: var(--u-color-neutral-400);
  border-radius: var(--u-border-radius-full);

  svg {
    pointer-events: none;
  }
}
