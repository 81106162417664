.base {
  display: inline-flex;
  align-items: center;
  justify-content: center;
  text-align: center;
  transition: background-color, box-shadow, 0.2s ease-in-out;
  text-decoration: none;

  &:focus {
    outline: none;
  }

  &:not(:disabled, [arial-disabled='true']):hover {
    cursor: pointer;
  }

  &--full-width {
    width: 100%;
  }

  &--variant-primary {
    background-color: var(--u-color-royal-500);
    border: 0;
    color: var(--u-color-white);

    &:hover {
      background-color: var(--u-color-royal-600);
    }

    &:focus-visible {
      background-color: var(--u-color-royal-600);
      outline: 2px solid var(--u-color-royal-200);
    }

    &:is(:disabled, [aria-disabled='true']) {
      background-color: var(--u-color-royal-300);
      color: var(--u-color-royal-100);
    }

    &.is-error {
      background-color: var(--u-color-coral-100);
      color: var(--u-color-coral-400);
    }
  }

  &--variant-secondary {
    background-color: var(--u-color-royal-100);
    border: 0;
    color: var(--u-color-royal-600);

    &:is(:hover, :focus-visible) {
      background-color: var(--u-color-royal-500);
      color: var(--u-color-white);
    }

    &:focus-visible {
      outline: 2px solid var(--u-color-royal-200);
    }

    &:is(:disabled, [aria-disabled='true']) {
      background-color: var(--u-color-royal-100);
      color: var(--u-color-royal-300);
    }

    &.is-error {
      background-color: var(--u-color-coral-100);
      color: var(--u-color-coral-400);
    }
  }

  &--variant-primary-outline {
    background-color: transparent;
    border: 1px solid var(--u-color-royal-300);
    color: var(--u-color-royal-600);

    &:is(:hover, :focus-visible) {
      background-color: var(--u-color-royal-600);
      border-color: var(--u-color-royal-600);
      color: var(--u-color-white);
    }

    &:focus-visible {
      outline: 2px solid var(--u-color-royal-200);
    }

    &:is(:disabled, [aria-disabled='true']) {
      background-color: var(--u-color-white);
      border-color: var(--u-color-royal-300);
      color: var(--u-color-royal-300);
    }

    &.is-error {
      background-color: var(--u-color-white);
      border-color: var(--u-color-coral-500);
      color: var(--u-color-coral-400);
    }
  }

  &--variant-white-outline {
    background-color: transparent;
    border: 1px solid var(--u-color-white);
    color: var(--u-color-white);

    &:is(:hover, :focus-visible) {
      background-color: var(--u-color-royal-600);
      border-color: var(--u-color-royal-600);
      color: var(--u-color-white);
    }

    &:focus-visible {
      outline: 2px solid var(--u-color-royal-200);
    }

    &:is(:disabled, [aria-disabled='true']) {
      background-color: var(--u-color-white);
      border-color: var(--u-color-royal-300);
      color: var(--u-color-royal-300);
    }

    &.is-error {
      background-color: var(--u-color-white);
      border-color: var(--u-color-coral-500);
      color: var(--u-color-coral-400);
    }
  }

  &--variant-ghost {
    border: 0;
    background-color: transparent;
    color: var(--u-color-royal-600);

    &:is(:hover) {
      background-color: var(--u-color-royal-600);
      color: var(--u-color-white);
    }

    &:focus-visible {
      outline: 2px solid var(--u-color-royal-200);
    }

    &:is(:disabled, [aria-disabled='true']) {
      background-color: transparent;
      color: var(--u-color-royal-300);
    }

    &.is-error {
      background-color: transparent;
      color: var(--u-color-coral-400);
    }
  }

  &--variant-danger {
    background-color: var(--u-color-coral-400);
    border: 0;
    color: var(--u-color-white);

    &:is(:hover, :focus-visible) {
      background-color: var(--u-color-coral-500);
    }

    &:focus-visible {
      outline: 2px solid var(--u-color-coral-200);
    }

    &:is(:disabled, [aria-disabled='true']) {
      background-color: var(--u-color-coral-200);
      color: var(--u-color-royal-050);
    }

    &.is-error {
      background-color: var(--u-color-coral-100);
      color: var(--u-color-coral-400);
    }
  }

  &--size-small {
    --u-button-content-font-size: var(--u-text-tiny-font-size);
    --u-button-content-line-height: var(--u-text-tiny-line-height);
    --u-button-content-letter-spacing: var(
      --u-text-tiny-callout-letter-spacing
    );
    --u-button-icon-wrapper-font-size: 16px;

    gap: var(--u-spacing-4);
    min-height: 32px;
    padding: var(--u-spacing-8) var(--u-spacing-12);

    border-radius: var(--u-border-radius-8);
  }

  &--size-medium {
    --u-button-content-font-size: var(--u-text-tiny-font-size);
    --u-button-content-line-height: var(--u-text-tiny-line-height);
    --u-button-content-letter-spacing: var(
      --u-text-tiny-callout-letter-spacing
    );
    --u-button-icon-wrapper-font-size: 16px;

    gap: var(--u-spacing-8);
    min-height: 48px;
    padding: var(--u-spacing-16);

    border-radius: var(--u-border-radius-12);
  }

  &--size-large {
    --u-button-content-font-size: var(--u-text-small-font-size);
    --u-button-content-line-height: var(--u-text-small-line-height);
    --u-button-content-letter-spacing: var(
      --u-text-small-callout-letter-spacing
    );
    --u-button-icon-wrapper-font-size: var(--u-spacing-20);

    gap: var(--u-spacing-8);
    min-height: 56px;
    padding: var(--u-spacing-16);

    border-radius: var(--u-border-radius-12);
  }
}

.content {
  font-size: var(--u-button-content-font-size);
  font-weight: 600;
  letter-spacing: var(--u-button-content-letter-spacing);
  line-height: var(--u-button-content-line-height);
  text-transform: uppercase;
}

.icon-wrapper {
  display: flex;
  align-items: center;

  font-size: var(--u-button-icon-wrapper-font-size);
}
