.base {
  display: inline-block;
  min-height: 36px;
  padding: var(--u-spacing-8) var(--u-spacing-12);

  background-color: var(--u-color-neutral-100);
  border-radius: var(--u-border-radius-full);
  color: var(--u-color-black);

  font-size: var(--u-text-small-font-size);
  line-height: var(--u-text-small-line-height);
  text-align: center;

  &--color-neutral {
    background-color: var(--u-color-neutral-100);
    color: var(--u-color-black);
  }

  &--color-error {
    background-color: var(--u-color-coral-100);
    color: var(--u-color-coral-500);
  }

  &--color-success {
    background-color: var(--u-color-alien-100);
    color: var(--u-color-alien-700);
  }

  &--color-warning {
    background-color: var(--u-color-vitaminC-100);
    color: var(--u-color-vitaminC-500);
  }

  &--color-info {
    background-color: var(--u-color-cerulian-100);
    color: var(--u-color-cerulian-500);
  }

  &--color-branded {
    background-color: var(--u-color-royal-100);
    color: var(--u-color-royal-500);
  }
}
