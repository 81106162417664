.base {
  position: relative;
  display: inline-block;
  z-index: 0;
  padding-left: var(--u-spacing-2);
  padding-top: var(--u-spacing-2);
}

.overlap-icon-wrapper {
  position: absolute;
  top: calc(50% - 2px);
  left: calc(50% - 2px);

  &::before {
    content: '';
    width: calc(100% + 2px);
    height: calc(100% + 2px);
    position: absolute;
    top: 0;
    left: 0;
    transform: translate(-1px, -1px);
    border-radius: 50%;
    background-color: #fff;
  }

  > :where(img, svg) {
    width: 100%;
    position: relative;
  }
}
