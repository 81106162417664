.base {
  display: flex;
  align-items: flex-end;
}

.modal-content {
  flex-grow: 1;
}

.content {
  height: 445px;
  border-top-left-radius: var(--u-border-radius-24);
  border-top-right-radius: var(--u-border-radius-24);
  background-color: var(--u-color-white);
  overflow: hidden;
  display: flex;
  flex-direction: column;
}
