.base {
  padding: var(--u-spacing-16) var(--u-spacing-24);

  border-radius: var(--u-spacing-12);
  border-width: 1px;
  border-style: solid;

  &--list {
    margin: 0;
    padding-left: var(--u-spacing-24);
  }

  &--color-info {
    border-color: var(--u-color-cerulean-500);
    background: var(--u-color-cerulean-050);
  }

  &--color-idle {
    border-color: var(--u-color-vitaminC-200);
    background: var(--u-color-vitaminC-050);
  }

  &--color-success {
    border-color: var(--u-color-alien-300);
    background: var(--u-color-alien-050);
  }
}
