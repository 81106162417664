.base {
  display: block;
  margin-bottom: var(--u-spacing-16);
}

.title {
  padding-top: var(--u-spacing-12);
  padding-bottom: var(--u-spacing-12);

  padding-left: var(--u-spacing-20);

  border-bottom: 1px solid var(--u-color-royal-050);
}
