@import '../../../../styles/src/lib/media.css';

.base {
  display: flex;
  align-items: center;
  justify-content: center;
  padding: var(--u-spacing-16);
}

.content {
  max-width: 472px;
  padding: var(--u-spacing-40) var(--u-spacing-64);

  background: var(--u-color-royal-050);
  border-radius: var(--u-border-radius-16);
  box-shadow: var(--u-box-shadow-main);

  text-align: center;
  overflow-y: auto;

  @media (--breakpoint-md-down) {
    max-width: 343px;
    padding: var(--u-spacing-40) var(--u-spacing-16) var(--u-spacing-24);
  }
}
