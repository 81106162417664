.base {
  position: relative;
  z-index: 0;

  display: inline-flex;
}

.popover {
  position: absolute;
  z-index: 0;

  &--placement-bottom-start {
    top: 100%;
    left: 0;

    flex-direction: column;
  }

  &--placement-bottom {
    top: 100%;
    left: 50%;

    flex-direction: column;

    transform: translate(-50%);

    > .arrow {
      align-self: center;
    }
  }

  &--placement-bottom-end {
    top: 100%;
    right: 0;

    flex-direction: column;
  }

  &--placement-top-start {
    bottom: 100%;
    left: 0;

    flex-direction: column-reverse;
  }

  &--placement-top {
    bottom: 100%;
    left: 50%;

    flex-direction: column-reverse;

    transform: translate(-50%);
  }

  &--placement-top-end {
    right: 0;
    bottom: 100%;

    flex-direction: column-reverse;
  }

  &--placement-left-start {
    top: 0;
    right: 100%;

    flex-direction: row-reverse;
  }

  &--placement-left {
    top: 50%;
    right: 100%;

    flex-direction: row-reverse;

    transform: translateY(-50%);
  }

  &--placement-left-end {
    right: 100%;
    bottom: 0;

    flex-direction: row-reverse;
  }

  &--placement-right-start {
    top: 0;
    left: 100%;
  }

  &--placement-right {
    top: 50%;
    left: 100%;

    transform: translateY(-50%);
  }

  &--placement-right-end {
    bottom: 0;
    left: 100%;
  }
}
