.base {
  &--network-btc,
  &--network-btc:hover {
    background-color: var(--u-color-vitaminC-100);
    color: var(--u-color-vitaminC-500);
    border-color: var(--u-color-vitaminC-500);
  }

  &--network-eth,
  &--network-eth:hover {
    background-color: var(--u-color-neutral-100);
    color: var(--u-color-neutral-500);
    border-color: var(--u-color-neutral-500);
  }

  &--network-polygon,
  &--network-polygon:hover,
  &--network-lightning,
  &--network-lightning:hover {
    background-color: var(--u-color-royal-100);
    color: var(--u-color-royal-500);
    border-color: var(--u-color-royal-500);
  }

  &--network-erd,
  &--network-erd:hover {
    background-color: #d3fdf8;
    color: var(--u-color-neutral-500);
    border-color: var(--u-color-neutral-500);
  }

  &--network-dash,
  &--network-dash:hover {
    background-color: var(--u-color-cerulean-100);
    color: var(--u-color-cerulean-500);
    border-color: var(--u-color-cerulean-500);
  }

  &--network-tron,
  &--network-tron:hover {
    background-color: #ffccd0;
    color: #ff0013;
    border-color: #ff0013;
  }
}
