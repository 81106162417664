.base {
  background-color: var(--u-color-white);
  background-image: repeating-linear-gradient(
    -45deg,
    #f9f9ff,
    #f9f9ff 1px,
    white 1px,
    white 7px
  );
  border: 1px solid var(--u-color-royal-100);

  &--color-red {
    background-color: var(--u-color-coral-050);
    background-image: repeating-linear-gradient(
      -45deg,
      #feebed,
      #feebed,
      var(--u-color-coral-050) 1px,
      var(--u-color-coral-050) 7px
    );
    border: 1px solid var(--u-color-coral-100);
  }

  &--color-green {
    background-color: var(--u-color-alien-050);
    background-image: repeating-linear-gradient(
      -45deg,
      #ecfdf2,
      #ecfdf2 1px,
      var(--u-color-alien-050) 1px,
      var(--u-color-alien-050) 7px
    );
    border: 1px solid var(--u-color-alien-100);
  }
}
