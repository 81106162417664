.base {
  display: grid;
  gap: var(--u-spacing-4);
  align-items: center;
  grid-template-columns: auto var(--u-spacing-16);

  padding-top: var(--u-spacing-16);
  padding-bottom: var(--u-spacing-16);
  padding: var(--u-spacing-16) var(--u-spacing-24);

  border-bottom: 1px solid var(--u-color-royal-050);

  color: var(--u-color-neutral-900);

  cursor: default;

  font-size: var(--u-text-small-font-size);
  line-height: var(--u-text-small-line-height);

  &:focus {
    outline: 0;
  }

  &[aria-selected='true'],
  &[data-state='checked'],
  &:focus,
  &:hover {
    color: var(--u-color-royal-500);
    background-color: var(--u-color-royal-050);
    font-weight: 700;
  }
}

.left-icon-wrapper {
  grid-column: 2/-1;
  display: flex;
}

.content {
  grid-column: 1/2;
}
